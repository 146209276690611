@import "colors.sass";
@import "variables.sass";

body {
    margin: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 0.9rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100vh;
    background: $background;
    overflow: hidden;
    color: $color-text; }

code {
    font-family: "Lato"; }

.app {

    .appbar {
        border-bottom: 1px solid #efefef;
        background: white;
        height: $menu-height;

        Button {
            height: auto;
            display: block; }


        .MuiCheckbox-colorSecondary.Mui-checked {
            color: $border; } } }

.outline {
    outline-width: 1px;
    outline-style: dashed;
    outline-color: $newtral-light;
    background-color: $background-light; }

.outline-none {
    outline: none; }


.editor-fullheight {
    height: calc(100% - #{$surfer-height} - 10px); }

.editor {
    height: calc(100% - #{$surfer-height} - #{$toolbar-to-editor-margin} - #{$menu-height} - 10px);
    overflow: auto;
    left: 26vw;
    width: 46vw;
    top: $toolbar-to-editor-margin + $menu-height;
    margin-bottom: 10px;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,.1);
    border: 1px solid $border;
    background: white;
    display: inline-block;
    position: absolute;

    .highlight {
        margin-top: 2vh;
        margin-left: 1vw; }

    .edit {
        margin-top: 2vh;
        margin-left: 1vw; }

    #edit-text {
        margin: 2vh;
        min-height: 20px;
        width: 77%;
        padding: 0px;
        float: right;
        background-color: $background-light;

        span {
            outline: none; }

        .edit-active {
            &:hover {
                outline-width: 1px;
                outline-style: dashed;
                outline-color: $newtral-light;
                background-color: $background-light; } }

        .speaker {
            margin-left: -25%;
            position: relative;
            margin-bottom: -26px;
            padding: 5px;
            width: 22%;
            font-size: 13px;

            &:hover {
                outline-width: 1px;
                outline-style: dashed;
                outline-color: $newtral-light;
                background-color: $background-light; }

            &:hover > .add-speaker {
                display: block; }

            .add-speaker {
                display: none;
                text-align: center; }

            .add-speaker::after {
                content: "";
                background-image: url('./icons/+-01.svg');
                background-size: cover;
                background-position: center;
                background-color: white;
                background-repeat: no-repeat;
                position: absolute;
                margin-left: -22px;
                margin-top: -2px;
                color: white;
                border-radius: 50%;
                width: 18px;
                text-align: center;
                cursor: pointer;
                font-size: 16px;
                height: 18px;
                line-height: 17px;
                display: inline-block; }

            &:hover > .remove-speaker {
                display: block; }

            .remove-speaker {
                display: none;
                text-align: center; }

            .remove-speaker::after {
                content: "";
                background-image: url('./icons/x-01.svg');
                background-size: cover;
                background-position: center;
                background-color: white;
                background-repeat: no-repeat;
                position: absolute;
                margin-left: 0px;
                margin-top: -2px;
                color: white;
                border-radius: 50%;
                width: 18px;
                text-align: center;
                cursor: pointer;
                font-size: 16px;
                height: 18px;
                line-height: 16px;
                display: inline-block; }

            input {
                border: none;
                margin: 0;
                padding: 0;
                width: 100%;
                outline: none; } }

        .paragraph {
            margin-bottom: 20px;
            padding: 5px;
            display: inline-block;
            line-height: 20px;
            width: 100%;

            &:hover {
                outline-width: 1px;
                outline-style: dashed;
                outline-color: $newtral-light;
                background-color: $background-light; }

            &:hover > .add-paragraph {
                display: block; }

            .add-paragraph {
                display: none;
                text-align: center; }

            .add-paragraph::after {
                content: "";
                background-image: url('./icons/+-01.svg');
                background-size: cover;
                background-position: center;
                background-color: white;
                background-repeat: no-repeat;
                position: absolute;
                margin-left: -28px;
                margin-top: -5px;
                color: white;
                border: 1px solid $newtral-light;
                border-radius: 50%;
                width: 20px;
                text-align: center;
                font-weight: bold;
                cursor: pointer;
                font-size: 20px;
                height: 20px;
                line-height: 18px;
                display: inline-block; }

            &:hover > .remove-paragraph {
                display: block; }

            .remove-paragraph {
                display: none;
                text-align: center; }

            .remove-paragraph::after {
                content: "";
                background-image: url('./icons/x-01.svg');
                background-size: cover;
                background-position: center;
                background-color: white;
                background-repeat: no-repeat;
                position: absolute;
                margin-left: 0px;
                margin-top: -5px;
                color: white;
                border: 1px solid red;
                border-radius: 50%;
                width: 20px;
                text-align: center;
                font-weight: 500;
                cursor: pointer;
                font-size: 20px;
                height: 20px;
                line-height: 17px;
                display: inline-block; } } } }

.mark-normal {
    background-color: #bbdefb;
    color: black; }

.mark-low {
    background-color: #e3f2fd;
    color: black; }

.mark-medium {
    background-color: #bbdefb;
    color: black; }

.mark-high {
    background-color: #aacaff;
    color: black; }

.mark-pinned {
    background-color: #1565c0;
    color: white; }

.marked-strong {
    background-color: #c1c1c163;
    color: black;
    border-bottom: 1px solid #5ac3ff !important; }

.count-marks {
    padding: 0 5px;
    margin: 0 2px; }

.button {
    margin-bottom: 2vh; }

.button-selected {
    color: rgba(0, 0, 0, 0.54) !important;
    background-color: rgba(0, 0, 0, 0.12) !important; }

.highlight-color {
    width: 32px;
    height: 16px; }

.toolbar-left {
    display: inherit;
    height: 100%; }

.toolbar-right {
    display: inherit;
    height: 100%; }

.show {
    display: block; }
.hide {
    display: none !important; }

.text-capitalize:first-letter {
    text-transform: capitalize; }

.metadata {
    max-height: 360px;
    overflow-y: auto;
    padding-right: 5px;
    margin-right: 1px !important;

    h3 {
        position: sticky;
        top: 0;
        background: #f8f8f8;
        font-weight: 500;
        display: block;
        margin: 20px 0 5px 0;
        padding: 0; }

    .metadata-title {
        display: block;
        font-weight: 500;
        text-transform: capitalize; }

    .metadata-value {
        display: block;
        margin-bottom: 5px;
        padding-right: 10px; } }


.waveform-line {
    position: absolute;
    margin-top: 17px;
    height: 1px;
    width: 100%;
    background-color: #1565c0; }

wave {
    width: 1; }

.fadewave {
    animation: fadewave 2s 1; }


@keyframes fadewave {
    0% {
        opacity: 0;
        width: 0; }
    100% {
        opacity: 1;
        width: 100%; } }

@media ( min-width: 1024px ) and ( max-height: 768px ) {
  .metadata {
      max-height: 190px;

      h3 {
        border-bottom: 1px solid #e7e7e7;
        padding-bottom: 5px; } } }

